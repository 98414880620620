<template>
  <li>
    <accordion-item
      v-show="isMenuItemVisible"
      :icon="menuItem.icon"
      :title="$t(`SIDEBAR.${menuItem.label}`)"
      :is-open="isContactSidebarItemOpen(menuItem.label)"
      @click="value => toggleSidebarUIState(menuItem.label, value)"
    >
      <ul
        class="list-none ml-0 mb-0"
        @mouseenter="showCommandsPopover"
        @mouseleave="hideCommandsPopover"
      >
        <secondary-child-nav-item
          v-for="(child, index) in menuItem.children"
          :id="child.id"
          :key="index"
          :to="child.toState"
          :label="child.label"
          :email="child.email"
          :thumbnail="child.thumbnail"
          :label-color="child.color"
          :should-truncate="child.truncateLabel"
          :icon="computedInboxClass(child)"
          :warning-icon="computedInboxErrorClass(child)"
          :counter="child.counter"
          :is-team-menu="isTeamMenu"
        />
        <router-link
          v-if="showItem(menuItem)"
          v-slot="{ href, navigate }"
          :to="menuItem.toState"
          custom
        >
          <li class="pl-1">
            <a :href="href">
              <woot-button
                size="tiny"
                variant="clear"
                color-scheme="secondary"
                icon="add"
                @click="e => newLinkClick(e, navigate)"
              >
                {{ $t(`SIDEBAR.${menuItem.newLinkTag}`) }}
              </woot-button>
            </a>
          </li>
        </router-link>
      </ul>
    </accordion-item>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';

import adminMixin from '../../../mixins/isAdmin';
import {
  getInboxClassByType,
  getInboxWarningIconClass,
} from 'dashboard/helper/inbox';

import SecondaryChildNavItem from './SecondaryChildNavItem';
import {
  isOnMentionsView,
  isOnUnattendedView,
} from '../../../store/modules/conversations/helpers/actionHelpers';
import AccordionItem from '../../Accordion/AccordionItem.vue';

import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import Sidebar from '../Sidebar.vue';

export default {
  components: { Sidebar, SecondaryChildNavItem, AccordionItem },
  mixins: [adminMixin, uiSettingsMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
    typeAccardion: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      globalConfig: 'globalConfig/get',
      agents: 'agents/getAgents',
    }),
    isActiveView() {
      return this.computedClass.includes('active-view');
    },
    hasSubMenu() {
      return !!this.menuItem.children;
    },
    isMenuItemVisible() {
      if (this.menuItem.globalConfigFlag) {
        return !!this.globalConfig[this.menuItem.globalConfigFlag];
      }
      if (this.menuItem.featureFlag) {
        return this.isFeatureEnabledonAccount(
          this.accountId,
          this.menuItem.featureFlag
        );
      }
      return true;
    },
    isAllConversations() {
      return (
        this.$store.state.route.name === 'inbox_conversation' &&
        this.menuItem.toStateName === 'home'
      );
    },
    isMentions() {
      return (
        isOnMentionsView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_mentions'
      );
    },
    isUnattended() {
      return (
        isOnUnattendedView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_unattended'
      );
    },
    isTeamsSettings() {
      return (
        this.$store.state.route.name === 'settings_teams_edit' &&
        this.menuItem.toStateName === 'settings_teams_list'
      );
    },
    isInboxSettings() {
      return (
        this.$store.state.route.name === 'settings_inbox_show' &&
        this.menuItem.toStateName === 'settings_inbox_list'
      );
    },
    isIntegrationsSettings() {
      return (
        this.$store.state.route.name === 'settings_integrations_webhook' &&
        this.menuItem.toStateName === 'settings_integrations'
      );
    },
    isApplicationsSettings() {
      return (
        this.$store.state.route.name === 'settings_applications_integration' &&
        this.menuItem.toStateName === 'settings_applications'
      );
    },
    isCurrentRoute() {
      return this.$store.state.route.name.includes(this.menuItem.toStateName);
    },

    isTeamMenu() {
      return this.menuItem?.key === 'team';
    },

    computedClass() {
      // If active inbox is present, do not highlight conversations
      if (this.activeInbox) return ' ';
      if (
        this.isAllConversations ||
        this.isMentions ||
        this.isUnattended ||
        this.isCurrentRoute
      ) {
        return 'bg-woot-25 dark:bg-slate-800 text-woot-500 dark:text-woot-500 hover:text-woot-500 dark:hover:text-woot-500 active-view';
      }
      if (this.hasSubMenu) {
        if (
          this.isTeamsSettings ||
          this.isInboxSettings ||
          this.isIntegrationsSettings ||
          this.isApplicationsSettings
        ) {
          return 'bg-woot-25 dark:bg-slate-800 text-woot-500 dark:text-woot-500 hover:text-woot-500 dark:hover:text-woot-500 active-view';
        }
        return 'hover:text-slate-700 dark:hover:text-slate-100';
      }

      return 'hover:text-slate-700 dark:hover:text-slate-100';
    },
  },
  methods: {
    computedInboxClass(child) {
      const { type, phoneNumber } = child;
      if (!type) return '';
      const classByType = getInboxClassByType(type, phoneNumber);
      return classByType;
    },
    computedInboxErrorClass(child) {
      const { type, reauthorizationRequired } = child;
      if (!type) return '';
      const warningClass = getInboxWarningIconClass(
        type,
        reauthorizationRequired
      );
      return warningClass;
    },
    newLinkClick(e, navigate) {
      if (this.menuItem.newLinkRouteName) {
        navigate(e);
      } else if (this.menuItem.showModalForNewItem) {
        if (this.menuItem.modalName === 'AddLabel') {
          e.preventDefault();
          this.$emit('add-label');
        }
      }
    },
    showItem(item) {
      return this.isAdmin && !!item.newLink && item?.newLink;
    },
    onClickOpen() {
      this.$emit('open');
    },

    showCommandsPopover() {
      if (this.isTeamMenu) {
        const chatWrapper = document.querySelector('.chat-list-wrapper');
        const wrapperApp = document.querySelector('.app-wrapper');
        if (chatWrapper && wrapperApp) {
          if (chatWrapper.scrollTop > 0) {
            chatWrapper.scrollTop = 0;
          }
          chatWrapper.classList.remove('overflow-auto');
          chatWrapper.classList.add('overflow-visible');
          wrapperApp.style.overflow = 'hidden';
        }
      }
    },
    hideCommandsPopover() {
      const chatWrapper = document.querySelector('.chat-list-wrapper');
      const wrapperApp = document.querySelector('.app-wrapper');
      if (chatWrapper) {
        chatWrapper.classList.remove('overflow-visible');
        chatWrapper.classList.add('overflow-auto');
        wrapperApp.style.overflow = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
