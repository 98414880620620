<template>
  <div class="wizard-body columns content-box small-9">
    <form @submit.prevent="updateTeam">
      <div class="timezone-input-wrap">
        <label>
          {{ $t('INBOX_MGMT.BUSINESS_HOURS.TIMEZONE_LABEL') }}
        </label>
        <multiselect
          v-model="timeZone"
          :options="timeZones"
          deselect-label=""
          select-label=""
          selected-label=""
          track-by="value"
          label="label"
          :close-on-select="true"
          :placeholder="$t('INBOX_MGMT.BUSINESS_HOURS.DAY.CHOOSE')"
          :allow-empty="false"
        />
      </div>

      <label>
        {{ $t('INBOX_MGMT.BUSINESS_HOURS.WEEKLY_TITLE') }}
      </label>
      <business-day
        v-for="timeSlot in timeSlots"
        :key="timeSlot.day"
        :day-name="dayNames[timeSlot.day]"
        :time-slot="timeSlot"
        @update="data => onSlotUpdate(timeSlot.day, data)"
      />
      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-submit-button
            :disabled="submitInProgress"
            :button-text="
              $t('TEAMS_SETTINGS.EDIT_FLOW.WORKING_TIME.BUTTON_TEXT')
            "
            :loading="submitInProgress"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import router from '../../../../index';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import BusinessDay from './BusinessDay';
import WootSubmitButton from '../../../../../components/buttons/FormSubmitButton';

import {
  timeSlotParse,
  timeSlotTransform,
  defaultTimeSlot,
  timeZoneOptions,
} from '../helpers/businessHour';

const DEFAULT_TIMEZONE = {
  label: 'Europe/Moscow',
  key: 'Europe/Moscow',
};

export default {
  components: {
    BusinessDay,
    WootSubmitButton,
  },
  mixins: [alertMixin, inboxMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dayNames: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
      timeSlots: [...defaultTimeSlot],
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
    hasError() {
      return this.timeSlots.filter(slot => slot.from && !slot.valid).length > 0;
    },
    timeZones() {
      return [...timeZoneOptions()];
    },
    teamId() {
      return this.$route.params.teamId;
    },
    currentTeam() {
      return this.$store.getters['teams/getTeam'](this.teamId);
    },
    timeZone: {
      get() {
        if (!this.currentTeam.timezone) return DEFAULT_TIMEZONE;
        return this.currentTeam.timezone;
      },
      set(value) {
        this.currentTeam.timezone = value;
      },
    },
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      const {
        working_hours: timeSlots = [],
        timezone: timeZone,
      } = this.currentTeam;
      const slots = timeSlotParse(timeSlots).length
        ? timeSlotParse(timeSlots)
        : defaultTimeSlot;
      this.timeSlots = slots;
      this.timeZone =
        this.timeZones.find(item => timeZone === item.value) ||
        DEFAULT_TIMEZONE;
    },
    onSlotUpdate(slotIndex, slotData) {
      this.timeSlots = this.timeSlots.map(item =>
        item.day === slotIndex ? slotData : item
      );
    },
    async updateTeam() {
      try {
        const { teamId } = this;
        const payload = {
          id: teamId,
          formData: false,
          working_hours: timeSlotTransform(this.timeSlots),
          timezone: this.timeZone.value,
        };
        await this.$store.dispatch('teams/update', payload);
        router.replace({
          name: 'settings_teams_add_assignment_strategy',
          params: {
            page: 'new',
            teamId,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.timezone-input-wrap {
  max-width: 60rem;

  &::v-deep .multiselect {
    margin-top: var(--space-small);
  }
}

.unavailable-input-wrap {
  max-width: 60rem;

  textarea {
    min-height: var(--space-jumbo);
    margin-top: var(--space-small);
  }
}

.business-hours-wrap {
  margin-bottom: var(--space-medium);
}

.richtext {
  padding: 0 var(--space-normal);
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  margin: 0 0 var(--space-normal);
}
</style>
