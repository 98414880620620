<template>
  <div class="left-sidebar">
    <v-dropdown
      :arrow="true"
      :disabled="!isTeamMenu"
      popover-base-class="popover-left-sidebar"
      :mount-self="true"
      :hover-portal="true"
      :placement="'right-center'"
      :z-index="2"
      :trigger="'hover'"
      :transition="'none'"
      @show="showCommandsPopover"
      @mouseleave="hideCommandsPopover"
    >
      <!-- This will be the popover target (for the events and position) -->
      <router-link
        v-slot="{ href, isActive, navigate }"
        :to="to"
        custom
        active-class="active"
      >
        <li
          class="font-medium h-7 my-1 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md dark:hover:bg-slate-800"
          :class="{
            'bg-woot-25 dark:bg-slate-800': isActive,
            'text-ellipsis overflow-hidden whitespace-nowrap max-w-full': shouldTruncate,
          }"
          @click="navigate"
        >
          <a
            :href="href"
            class="inline-flex text-left max-w-full w-full items-center width-100"
          >
            <span
              v-if="thumbnail"
              class="inline-flex items-center justify-center w-4 rounded-sm bg-slate-100 dark:bg-slate-700 p-0.5 mr-1.5 rtl:mr-0 rtl:ml-1.5"
            >
              <img :src="thumbnail" :alt="label" />
            </span>
            <span
              v-if="icon"
              class="inline-flex items-center justify-center w-4 rounded-sm bg-slate-100 dark:bg-slate-700 p-0.5 mr-1.5 rtl:mr-0 rtl:ml-1.5"
            >
              <fluent-icon
                class="text-xxs text-slate-700 dark:text-slate-200"
                :class="{
                  'text-woot-500 dark:text-woot-500': isActive,
                }"
                :icon="icon"
                size="12"
              />
            </span>

            <span
              v-if="labelColor"
              class="inline-flex rounded-sm bg-slate-100 h-3 w-3.5 mr-1.5 rtl:mr-0 rtl:ml-1.5 border border-slate-50 dark:border-slate-900"
              :style="{ backgroundColor: labelColor }"
            />
            <div
              class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"
            >
              <span
                :title="menuTitle"
                class="text-sm text-slate-700 dark:text-slate-100"
                :class="{
                  'text-woot-500 dark:text-woot-500': isActive,
                  'text-ellipsis overflow-hidden whitespace-nowrap max-w-full': shouldTruncate,
                }"
              >
                {{ label }}
              </span>
              <span
                v-if="counter || counter === 0"
                class="text-slate-700 dark:text-slate-100 text-xs bg-slate-50 dark:bg-slate-700 rounded-full min-w-[18px] justify-center items-center flex text-xxs font-medium mx-1 py-0.5 px-1.5"
              >
                {{ counter }}
              </span>
            </div>
            <span
              v-if="warningIcon"
              class="inline-flex rounded-sm mr-1 bg-slate-100"
            >
              <fluent-icon
                v-tooltip.top-end="$t('SIDEBAR.FACEBOOK_REAUTHORIZE')"
                class="text-xxs"
                :icon="warningIcon"
                size="12"
              />
            </span>
          </a>
        </li>
      </router-link>
      <!-- This will be the content of the popover -->
      <template #portal>
        <div @show="showCommandsPopover" @mouseleave="hideCommandsPopover">
          <loading-state v-if="loaderPeople" />
          <div v-else>
            <div
              v-if="peopleTeam.length > 0"
              class="teammates custom-scroll text-slate-700 dark:text-slate-100"
            >
              <div
                class="flex justify-between width-100 font-medium h-7 text-slate-700 dark:text-slate-100 my-1 flex items-center px-2 rounded-md"
              >
                <span>Всего</span>
                <span> {{ peopleTeam.length }} </span>
              </div>

              <div
                class="flex justify-between width-100 font-medium text-slate-700 dark:text-slate-100 h-7 my-1 flex items-center px-2 rounded-md margin-top-1"
              >
                <span>Активных</span>
                <span> {{ activePeopleTeam.length }} </span>
              </div>
              <ul>
                <div v-if="activePeopleTeam.length === 0">
                  <span class="text-sm">Нет людей в данной команде</span>
                </div>

                <li
                  v-for="people in activePeopleTeam"
                  v-else
                  class="font-medium h-7 my-1 flex items-center px-2 rounded-md"
                >
                  <router-link
                    :to="linkToPeople(people.id)"
                    active-class="active"
                    class="flex width-100 text-sm text-slate-700 dark:text-slate-100"
                  >
                    <div class="flex justify-between width-100">
                      <div :title="menuTitle" class="text-sm">
                        <div class="flex">
                          <thumbnail
                            v-tooltip.top="
                              availabilityDisplayLabel(
                                people.availability_status
                              )
                            "
                            :src="people.thumbnail"
                            :username="people.name"
                            :should-show-status-always="true"
                            :status="people.availability_status"
                            size="20px"
                            class="mr-2 rtl:mr-0 rtl:ml-2"
                          />
                          <span class="text-ellipsis overflow-hidden">
                            {{ people.name }}
                          </span>
                        </div>
                      </div>
                      <span
                        class="text-sm text-slate-700 dark:text-slate-100 pl-1"
                      >
                        {{ people.assigned_conversations }}
                      </span>
                    </div>
                  </router-link>
                </li>
              </ul>
              <div
                class="flex justify-between width-100 font-medium h-7 my-1 flex items-center px-2 rounded-md margin-top-1"
              >
                <span>Неактивных</span>
                <span> {{ noActivePeopleTeam.length }} </span>
              </div>

              <ul>
                <div v-if="noActivePeopleTeam.length === 0">
                  <span class="text-sm">Нет людей в данной команде</span>
                </div>

                <li
                  v-for="people in noActivePeopleTeam"
                  v-else
                  class="font-medium h-7 my-1 flex items-center px-2 rounded-md"
                >
                  <router-link
                    :to="linkToPeople(people.id)"
                    active-class="active"
                    class="flex width-100 text-sm text-slate-700 dark:text-slate-100"
                  >
                    <div class="flex justify-between width-100">
                      <div :title="menuTitle" class="text-sm">
                        <div class="flex">
                          <thumbnail
                            v-tooltip.top="
                              availabilityDisplayLabel(
                                people.availability_status
                              )
                            "
                            :src="people.thumbnail"
                            :username="people.name"
                            :should-show-status-always="true"
                            :status="people.availability_status"
                            size="20px"
                            class="mr-2 rtl:mr-0 rtl:ml-2"
                          />
                          <span class="text-ellipsis overflow-hidden">
                            {{ people.name }}
                          </span>
                        </div>
                      </div>
                      <span
                        class="text-sm text-slate-700 dark:text-slate-100 pl-1"
                      >
                        {{ people.assigned_conversations }}
                      </span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <div v-if="peopleTeam.length === 0">
              Нет людей в данной команде
            </div>
          </div>
        </div>
      </template>
    </v-dropdown>
  </div>
</template>
<script>
import LoadingState from '../../widgets/LoadingState.vue';
import Thumbnail from '../../widgets/Thumbnail.vue';
import wootConstants from 'dashboard/constants/globals';
import { mapGetters } from 'vuex';
import { frontendURL } from '../../../helper/URLHelper';
import { VDropdown } from 'vue-2-dropdown';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  components: { Thumbnail, LoadingState, VDropdown },
  props: {
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    counter: {
      type: Number,
    },
    labelColor: {
      type: String,
      default: '',
    },
    shouldTruncate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: String,
      default: '',
    },
    warningIcon: {
      type: String,
      default: '',
    },
    isTeamMenu: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isPopoverVisible: false,
      currentTeam: {
        type: Number,
        default: null,
      },
      peopleTeamActive: {
        type: Array,
        default: [],
      },
      peopleTeam: {
        type: Array,
        default: [],
      },
      loaderPeople: {
        type: Boolean,
        default: false,
      },
      positionPopover: {
        type: Object,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
    }),
    showIcon() {
      return {
        'overflow-hidden whitespace-nowrap text-ellipsis': this.shouldTruncate,
      };
    },
    menuTitle() {
      return this.shouldTruncate ? this.label : '';
    },
    statusStyle() {
      const statusSize = `20px`;
      return { width: statusSize, height: statusSize };
    },
    activePeopleTeam() {
      return this.peopleTeam.filter(
        item => item?.availability_status === 'online'
      );
    },
    noActivePeopleTeam() {
      return this.peopleTeam.filter(
        item => item?.availability_status !== 'online'
      );
    },
  },
  methods: {
    showCommandsPopover() {
      if (this.isTeamMenu) {
        this.currentTeam = this.id;
        this.isPopoverVisible = true;
        this.peopleTeam = this.$store.getters['teamMembers/getTeamMembers'](
          this.currentTeam
        );
        if (!this.peopleTeam?.length) {
          this.loaderPeople = true;
          this.$store
            .dispatch('teamMembers/get', {
              teamId: this.currentTeam,
            })
            .then(res => {
              this.peopleTeam = res;
            })
            .finally(() => {
              this.loaderPeople = false;
            });
        } else {
          this.loaderPeople = false;
        }
      }
    },
    hideCommandsPopover() {
      this.currentTeam = null;
      this.isPopoverVisible = false;
    },

    availabilityDisplayLabel(status) {
      const availabilityIndex = AVAILABILITY_STATUS_KEYS.findIndex(
        key => key === status
      );
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[
        availabilityIndex
      ];
    },

    linkToPeople(agentId) {
      return frontendURL(`accounts/${this.currentAccountId}/agents/${agentId}`);
    },
  },
};
</script>
<style lang="scss">
.ui-dropdown-portal {
  background: var(--bg-slate-900);

  &__layout {
    background: #fff !important;
    padding: 10px !important;
    color: #000000 !important;

    span {
      color: #000000 !important;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-button {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #aaa;
      border: 1px none #ffffff;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #777;
    }

    &::-webkit-scrollbar-thumb:active {
      background: #666;
    }

    &::-webkit-scrollbar-track {
      background: #eee;
      border: 65px none #ffffff;
      border-radius: 34px;
    }

    &::-webkit-scrollbar-track:hover {
      background: #eee;
    }

    &::-webkit-scrollbar-track:active {
      background: #eee;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    @apply p-1 z-40 text-xs rounded-md;
  }

  &__arrow {
    border-color: var(--bg-slate-900);
  }
}

.trigger {
  width: 100%;
}
</style>

<style lang="scss" scoped>

.teammates {
  max-height: 300px;
  overflow-y: auto;
}

.user-online-status {
  border-radius: 50%;
  bottom: var(--space-micro);
  width: 20px;
  height: 20px;

  &:after {
    content: ' ';
  }
}

.user-online-status--online {
  @apply bg-green-500 dark:bg-green-500;
}

.user-online-status--busy {
  @apply bg-yellow-500 dark:bg-yellow-500;
}

.user-online-status--on_call {
  @apply bg-yellow-500 dark:bg-yellow-500;
}

.user-online-status--studying {
  @apply bg-violet-500 dark:bg-violet-500;
}

.user-online-status--moved_away {
  @apply bg-violet-500 dark:bg-violet-500;
}

.user-online-status--lunch {
  @apply bg-violet-500 dark:bg-violet-500;
}

.user-online-status--on_side_task {
  @apply bg-violet-500 dark:bg-violet-500;
}

.user-online-status--offline {
  @apply bg-red-500 dark:bg-red-500;
}
</style>
