var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-sidebar"},[_c('v-dropdown',{attrs:{"arrow":true,"disabled":!_vm.isTeamMenu,"popover-base-class":"popover-left-sidebar","mount-self":true,"hover-portal":true,"placement":'right-center',"z-index":2,"trigger":'hover',"transition":'none'},on:{"show":_vm.showCommandsPopover,"mouseleave":_vm.hideCommandsPopover},scopedSlots:_vm._u([{key:"portal",fn:function(){return [_c('div',{on:{"show":_vm.showCommandsPopover,"mouseleave":_vm.hideCommandsPopover}},[(_vm.loaderPeople)?_c('loading-state'):_c('div',[(_vm.peopleTeam.length > 0)?_c('div',{staticClass:"teammates custom-scroll text-slate-700 dark:text-slate-100"},[_c('div',{staticClass:"flex justify-between width-100 font-medium h-7 text-slate-700 dark:text-slate-100 my-1 flex items-center px-2 rounded-md"},[_c('span',[_vm._v("Всего")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.peopleTeam.length)+" ")])]),_vm._v(" "),_c('div',{staticClass:"flex justify-between width-100 font-medium text-slate-700 dark:text-slate-100 h-7 my-1 flex items-center px-2 rounded-md margin-top-1"},[_c('span',[_vm._v("Активных")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.activePeopleTeam.length)+" ")])]),_vm._v(" "),_c('ul',[(_vm.activePeopleTeam.length === 0)?_c('div',[_c('span',{staticClass:"text-sm"},[_vm._v("Нет людей в данной команде")])]):_vm._l((_vm.activePeopleTeam),function(people){return _c('li',{staticClass:"font-medium h-7 my-1 flex items-center px-2 rounded-md"},[_c('router-link',{staticClass:"flex width-100 text-sm text-slate-700 dark:text-slate-100",attrs:{"to":_vm.linkToPeople(people.id),"active-class":"active"}},[_c('div',{staticClass:"flex justify-between width-100"},[_c('div',{staticClass:"text-sm",attrs:{"title":_vm.menuTitle}},[_c('div',{staticClass:"flex"},[_c('thumbnail',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                            _vm.availabilityDisplayLabel(
                              people.availability_status
                            )
                          ),expression:"\n                            availabilityDisplayLabel(\n                              people.availability_status\n                            )\n                          ",modifiers:{"top":true}}],staticClass:"mr-2 rtl:mr-0 rtl:ml-2",attrs:{"src":people.thumbnail,"username":people.name,"should-show-status-always":true,"status":people.availability_status,"size":"20px"}}),_vm._v(" "),_c('span',{staticClass:"text-ellipsis overflow-hidden"},[_vm._v("\n                          "+_vm._s(people.name)+"\n                        ")])],1)]),_vm._v(" "),_c('span',{staticClass:"text-sm text-slate-700 dark:text-slate-100 pl-1"},[_vm._v("\n                      "+_vm._s(people.assigned_conversations)+"\n                    ")])])])],1)})],2),_vm._v(" "),_c('div',{staticClass:"flex justify-between width-100 font-medium h-7 my-1 flex items-center px-2 rounded-md margin-top-1"},[_c('span',[_vm._v("Неактивных")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.noActivePeopleTeam.length)+" ")])]),_vm._v(" "),_c('ul',[(_vm.noActivePeopleTeam.length === 0)?_c('div',[_c('span',{staticClass:"text-sm"},[_vm._v("Нет людей в данной команде")])]):_vm._l((_vm.noActivePeopleTeam),function(people){return _c('li',{staticClass:"font-medium h-7 my-1 flex items-center px-2 rounded-md"},[_c('router-link',{staticClass:"flex width-100 text-sm text-slate-700 dark:text-slate-100",attrs:{"to":_vm.linkToPeople(people.id),"active-class":"active"}},[_c('div',{staticClass:"flex justify-between width-100"},[_c('div',{staticClass:"text-sm",attrs:{"title":_vm.menuTitle}},[_c('div',{staticClass:"flex"},[_c('thumbnail',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                            _vm.availabilityDisplayLabel(
                              people.availability_status
                            )
                          ),expression:"\n                            availabilityDisplayLabel(\n                              people.availability_status\n                            )\n                          ",modifiers:{"top":true}}],staticClass:"mr-2 rtl:mr-0 rtl:ml-2",attrs:{"src":people.thumbnail,"username":people.name,"should-show-status-always":true,"status":people.availability_status,"size":"20px"}}),_vm._v(" "),_c('span',{staticClass:"text-ellipsis overflow-hidden"},[_vm._v("\n                          "+_vm._s(people.name)+"\n                        ")])],1)]),_vm._v(" "),_c('span',{staticClass:"text-sm text-slate-700 dark:text-slate-100 pl-1"},[_vm._v("\n                      "+_vm._s(people.assigned_conversations)+"\n                    ")])])])],1)})],2)]):_vm._e(),_vm._v(" "),(_vm.peopleTeam.length === 0)?_c('div',[_vm._v("\n            Нет людей в данной команде\n          ")]):_vm._e()])],1)]},proxy:true}])},[_c('router-link',{attrs:{"to":_vm.to,"custom":"","active-class":"active"},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive, navigate }){return [_c('li',{staticClass:"font-medium h-7 my-1 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md dark:hover:bg-slate-800",class:{
          'bg-woot-25 dark:bg-slate-800': isActive,
          'text-ellipsis overflow-hidden whitespace-nowrap max-w-full': _vm.shouldTruncate,
        },on:{"click":navigate}},[_c('a',{staticClass:"inline-flex text-left max-w-full w-full items-center width-100",attrs:{"href":href}},[(_vm.thumbnail)?_c('span',{staticClass:"inline-flex items-center justify-center w-4 rounded-sm bg-slate-100 dark:bg-slate-700 p-0.5 mr-1.5 rtl:mr-0 rtl:ml-1.5"},[_c('img',{attrs:{"src":_vm.thumbnail,"alt":_vm.label}})]):_vm._e(),_vm._v(" "),(_vm.icon)?_c('span',{staticClass:"inline-flex items-center justify-center w-4 rounded-sm bg-slate-100 dark:bg-slate-700 p-0.5 mr-1.5 rtl:mr-0 rtl:ml-1.5"},[_c('fluent-icon',{staticClass:"text-xxs text-slate-700 dark:text-slate-200",class:{
                'text-woot-500 dark:text-woot-500': isActive,
              },attrs:{"icon":_vm.icon,"size":"12"}})],1):_vm._e(),_vm._v(" "),(_vm.labelColor)?_c('span',{staticClass:"inline-flex rounded-sm bg-slate-100 h-3 w-3.5 mr-1.5 rtl:mr-0 rtl:ml-1.5 border border-slate-50 dark:border-slate-900",style:({ backgroundColor: _vm.labelColor })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"},[_c('span',{staticClass:"text-sm text-slate-700 dark:text-slate-100",class:{
                'text-woot-500 dark:text-woot-500': isActive,
                'text-ellipsis overflow-hidden whitespace-nowrap max-w-full': _vm.shouldTruncate,
              },attrs:{"title":_vm.menuTitle}},[_vm._v("\n              "+_vm._s(_vm.label)+"\n            ")]),_vm._v(" "),(_vm.counter || _vm.counter === 0)?_c('span',{staticClass:"text-slate-700 dark:text-slate-100 text-xs bg-slate-50 dark:bg-slate-700 rounded-full min-w-[18px] justify-center items-center flex text-xxs font-medium mx-1 py-0.5 px-1.5"},[_vm._v("\n              "+_vm._s(_vm.counter)+"\n            ")]):_vm._e()]),_vm._v(" "),(_vm.warningIcon)?_c('span',{staticClass:"inline-flex rounded-sm mr-1 bg-slate-100"},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(_vm.$t('SIDEBAR.FACEBOOK_REAUTHORIZE')),expression:"$t('SIDEBAR.FACEBOOK_REAUTHORIZE')",modifiers:{"top-end":true}}],staticClass:"text-xxs",attrs:{"icon":_vm.warningIcon,"size":"12"}})],1):_vm._e()])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }