export const getters = {
  getTeams($state) {
    return Object.values($state.records)
      .filter(item => item?.id)
      .sort((a, b) => a?.id - b?.id);
  },
  getMyTeams($state, $getters) {
    return $getters.getTeams.filter(team => {
      if (!team?.is_member) {
        return;
      }
      const { is_member: isMember } = team;
      return isMember;
    });
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getTeam: $state => id => {
    const team = $state.records[id];
    return team || {};
  },
};
