export const ASSIGNMENT_STRATEGIES = [
  {
    key: 'random',
    value: 'Random',
  },
  {
    key: 'less_conversations',
    value: 'Less Conversations',
  },
];
