<template>
  <div class="resize" :class="positionClass" @mousedown="initResize">
    <div :class="positionClass" class="icon">
      <svg
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.975 9.689a1 1 0 1 1-1.45-1.378l4.75-5a1 1 0 0 1 1.45 0l4.75 5a1 1 0 1 1-1.45 1.378L13 6.505v10.99l3.025-3.184a1 1 0 1 1 1.45 1.378l-4.75 5a1 1 0 0 1-1.45 0l-4.75-5a1 1 0 1 1 1.45-1.378L11 17.495V6.505L7.975 9.689Z"
        />
      </svg>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    position: {
      type: String,
      required: true,
    },
  },
  computed: {
    positionClass() {
      return {
        horizontal: this.position === 'horizontal',
        'horizontal-right': this.position === 'horizontal-right',
        vertical: this.position === 'vertical',
      };
    },
  },
  methods: {
    initResize(event) {
      // Вызов события начала изменения размера
      this.$emit('startResize', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.resize {
  width: 20px;
  height: 20px;
  position: absolute;
  cursor: ew-resize;
  z-index: 999999;
  &.horizontal {
    right: 0;
    top: 50%;
  }
  &.vertical {
    top: 0;
    left: 50%;
    cursor: ns-resize;
  }
  &.horizontal-right {
    left: 0;
    top: 50%;
  }
  .icon {
    width: 100%;
    height: 100%;
    svg {
      width: 100% !important;
      height: 100% !important;
      fill: #c9d4ee;
    }
    &.horizontal {
      transform: rotate(90deg);
    }
    &.horizontal-right {
      transform: rotate(90deg);
    }
    &.vertical {
      transform: rotate(0deg);
    }
  }
}
</style>
